import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginLoaderScreen from './pages/login_loader/login_loader_screen.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/common.css';
import './assets/css/nch.css';
import './assets/scss/common.scss';
import './assets/css/style.css';
import { Provider } from "react-redux";
import store from "./store.js";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Page404 = React.lazy(() => import('./pages/redirects/404'))
const Page500 = React.lazy(() => import('./pages/redirects/500'))

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

function App() {
    // localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTYyMjYzOTkxMCwiZXhwIjoxNjM4MTkxOTEwfQ.j95S0uLGaegGU3JkZLpUeRQC5s-MnIyKhVt-SgsHNtrmGtXn8oRN4N7khL8WLT1fEuGXpbcEv6mkdcaK-gt3JQ');
    // localStorage.setItem('user', "{\"identifier\":\"NIC\",\"status\":\"active\",\"username\":\"admin\",\"code\":\"U-100001\",\"title\":\"Mr.\",\"email\":\"admin@mail.com\",\"dp\":null,\"landline\":\"0112313212\",\"dob\":\"1974-04-02\",\"gender\":\"Male\",\"language\":null,\"id\":\"04d7f995-ef33-4870-a214-4e21c51ff76e\",\"job_role\":{\"description\":null,\"name\":\"Employee\",\"id\":\"1\"},\"blood_group\":null,\"marital_status\":null,\"epf_number\":null,\"locker_number\":null,\"basic_salary\":null,\"salary_term\":null,\"contact_primary\":\"0771231231\",\"contact_secondary\":null,\"address\":{\"city\":\"A est in culpa ex n\",\"homeNo\":\"3123\",\"streetName\":\"Carolyn Gates\",\"postalCode\":\"Ut velit facilis ape\",\"province\":\"Perferendis in sint\",\"district\":\"Omnis excepteur omni\",\"id\":\"e62a6c71-94a4-4d5f-9856-91fac4fd699c\",\"country\":\"Ut nobis proident a\"},\"identifier_value\":\"94232313132v\",\"first_name\":\"admin\",\"last_name\":\"admin\",\"middle_name\":\"hr\",\"joined_date\":null,\"contract_period\":null}");
    return (
        <>
            <BrowserRouter>
                <Provider store={store}>
                    <React.Suspense fallback={loading}>
                        <Switch>
                            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                            <Route exact path="/login" name="Login Loader" component={LoginLoaderScreen} />
                            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                        </Switch>
                    </React.Suspense>
                </Provider>
            </BrowserRouter>
        </>
    );
}

export default App;
